/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  border-top: 1px solid $color-border;
  font-size: 0.875rem;
  margin-top: 2em;
  padding-top: 1em;
}
