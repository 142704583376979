/*
  Project: Ksiegowa
  Author: Daniel
 */

@import "settings/_global.scss";
@import "tools/_breakpoints.scss";
@import "tools/_clearfix.scss";
@import "tools/_hidden.scss";
@import "generic/_box-sizing.scss";
@import "generic/_font-face.scss";
@import "generic/_normalize.scss";
@import "generic/_reset.scss";
@import "generic/_shared.scss";
@import "elements/_blockquote.scss";
@import "elements/_headings.scss";
@import "elements/_hr.scss";
@import "elements/_html.scss";
@import "elements/_images.scss";
@import "elements/_links.scss";
@import "elements/_lists.scss";
@import "elements/_tables.scss";
@import "objects/_layout.scss";
@import "objects/_list-bare.scss";
@import "objects/_list-inline.scss";
@import "objects/_media.scss";
@import "objects/_table.scss";
@import "objects/_wrapper.scss";
@import "components/_btn.scss";
@import "components/_comment-form.scss";
@import "components/_comment.scss";
@import "components/_cookies.scss";
@import "components/_footer.scss";
@import "components/_header.scss";
@import "components/_main-nav.scss";
@import "components/_post.scss";
@import "utilities/_align.scss";
@import "utilities/_clearfix.scss";
@import "utilities/_hide.scss";
@import "vendor/animate.scss";
@import "vendor/bootstrap.min.scss";
@import "vendor/default.scss";
@import "vendor/font-awesome.scss";
@import "vendor/icomoon.scss";
@import "vendor/owl.carousel.scss";
@import "vendor/pogo-slider.min.scss";
@import "vendor/slider.scss";
@import "vendor/styles.scss";

.text-content {

  ul {list-style: none; margin-left: 1.5em;}
  li::before {content: "•"; font-size: 18px;color: #18ba60;
    display: inline-block; width: 1em;
    margin-left: -1em}
}


.c-form__message {
  margin: 3em auto 0 auto;
  border: 2px solid #eee;
  padding: 1em;
  max-width: 25rem;
  text-align: center;
}

.c-form__message--thank-you {
  border-color: #eee;
  color: #000;
}

.c-form__message--error {
  border-color: red;
  color: red;
}
.g-recaptcha {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .page-id-171 .page-title-section {
    background: none!important;
  
    h1 {
      color: #000;
    }
  }
}
